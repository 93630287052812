import Produktkategorie from '@/entities/common/produktkategorie'
import Pdukt, { Auswahl, Subprodukt } from '@/entities/member/pdukt'
import { Auswahl as PduktAuswahl } from '@/entities/member/pliste_pdukt'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import PduktRequest from '@/entities/member/request/pdukt_request'
import PduktList from '@/entities/member/lists/pdukt_list'
import RestPaths from '@/util/rest_paths'
import Pliste from '@/entities/member/pliste'
import PlistePdukt, { SubproduktOption } from '@/entities/member/pliste_pdukt'
import PlisteUpdateRequest, {
  Kategorie as ReqKategorie,
  KategorieZeitraum as ReqKategorieZeitraum,
  Produkt as ReqProdukt,
  Extra as ReqExtra,
  Auswahl as ReqAuswahl,
  Subprodukt as ReqSubprodukt,
  Option as ReqOption,
} from '@/entities/member/request/pliste_update_request'
import moment from 'moment'
import Swapper from '@/util/swapper'
import {
  Kategorie as KategorieComponent,
  KategorieZeitraum as KategorieZeitraumComponent,
} from '@/components/custom/kategorie'

export default class NewPreislisteEditMemcommonLogic {
  preisliste: Pliste = null
  preislisteId: string = null

  bezeichnung: string = null
  filiale?: string = null
  gueltigVon?: string = null
  gueltigBis?: string = null

  gueltigVonVisible: boolean = false
  gueltigBisVisible: boolean = false

  kategorienWaehlenVisible: boolean = false
  produkteWaehlenVisible: boolean = false

  //listExtras: PreislisteExtra[] = []

  kategorieNeu: KategorieComponent = null
  kategorieEdit: KategorieComponent = null
  kategorieEditBackup: Kategorie = null

  //Produkt Extras ohne Preis
  extras: Pdukt[] = []
  //Alle aktiven Produkte ohne Preis
  produkte: Pdukt[] = []
  //Kategorien für Preislisten
  //kategorien: Produktkategorie[] = []

  //Fertige Kategorien
  preislisteKategorien: Kategorie[] = []

  //Preisliste Kategorie, zu der Produkte
  //hinzugefügt werden
  aktuelleKategorie: Kategorie = null

  //Produkte, die zur aktuellen Kategorie hinzugefügt werden
  //sollen
  gewaehlteProdukte: Pdukt[] = []

  vorhandeneExtras: PreislisteExtra[] = []

  editWorking: boolean = false
  editCompleted: boolean = false
  editFormDisabled: boolean = false
  editError: boolean = false

  kategorienLoaded: boolean = false
  extrasLoaded: boolean = false
  produkteLoaded: boolean = false

  kategorieNeuDialogVisible = false
  kategorieEditDialogVisible = false

  kategorieHinzufuegen(kategorie: Produktkategorie) {}

  neueKategorieErstellen() {
    this.kategorieNeu = new KategorieComponent()
    this.kategorieNeuDialogVisible = true
  }

  neueKategorieAbbrechen() {
    this.kategorieNeuDialogVisible = false
    this.kategorieNeu = null
  }

  neueKategorieEinfuegen() {
    var kategorie = new Kategorie()
    kategorie.bezeichnung = this.kategorieNeu.bezeichnung
    kategorie.beschreibung = this.kategorieNeu.beschreibung
    kategorie.zeitbegrenzung = this.kategorieNeu.zeitbegrenzung
    this.kategorieNeu.zeitraeume.forEach((zeitraum) => {
      var z = new KategorieZeitraum()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      kategorie.zeitraeume.push(z)
    })
    this.preislisteKategorien.push(kategorie)

    this.kategorieNeuDialogVisible = false
  }

  // neuerKategorieZeitraum() {
  //   this.kategorieNeu.zeitraeume.push(new KategorieZeitraumComponent())
  // }

  // neuerKategorieEditZeitraum() {
  //   this.kategorieEdit.zeitraeume.push(new KategorieZeitraumComponent())
  // }

  moveKategorieUp(index: number) {
    Swapper.instance.pushUp(this.preislisteKategorien, index)
  }

  moveKategorieDown(index: number) {
    Swapper.instance.pushDown(this.preislisteKategorien, index)
  }

  //Preiliste Kategorie löschen
  kategorieEntfernen(kategorie: Kategorie) {
    var index = this.preislisteKategorien.indexOf(kategorie)
    this.preislisteKategorien.splice(index, 1)
  }

  kategorieProdukteWaehlen(kategorie: Kategorie) {
    this.aktuelleKategorie = kategorie
    this.gewaehlteProdukte = []
    this.produkteWaehlenVisible = true
  }

  createProdukt(produkt: Pdukt): PreislisteProdukt {
    var preislisteProdukt = new PreislisteProdukt()
    //preislisteProdukt.id = uuidv4()
    preislisteProdukt.produkt = produkt
    //this.aktuelleKategorie.produkte.push(preislisteProdukt)

    produkt.extras.forEach((extra) => {
      var preislisteProduktExtra = new PreislisteProduktExtra()
      preislisteProduktExtra.id = uuidv4()
      preislisteProduktExtra.extraId = extra.id
      preislisteProduktExtra.bezeichnung = extra.produkt.bezeichnung
      preislisteProduktExtra.artikelNr = extra.produkt.artikelNr
      preislisteProdukt.extras.push(preislisteProduktExtra)
    })

    produkt.auswahlen.forEach((auswahl) => {
      var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
      //preislisteProduktAuswahl.id = uuidv4()
      preislisteProduktAuswahl.auswahl = auswahl

      auswahl.optionen.forEach((option) => {
        var preislisteProduktExtra = new PreislisteProduktExtra()
        //preislisteProduktExtra.id = uuidv4()
        preislisteProduktExtra.extraId = option.id
        preislisteProduktExtra.bezeichnung = option.produkt.bezeichnung
        preislisteProduktExtra.artikelNr = option.produkt.artikelNr
        preislisteProduktAuswahl.optionen.push(preislisteProduktExtra)
      })

      preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
    })

    produkt.subprodukte.forEach((subprodukt) => {
      var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
      //preislisteProduktSubprodukt.id = uuidv4()
      preislisteProduktSubprodukt.subprodukt = subprodukt
      subprodukt.optionen.forEach((suboption) => {
        var preislisteProduktOption = this.createProdukt(suboption.produkt)
        preislisteProduktSubprodukt.optionen.push(preislisteProduktOption)
      })
      preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)
    })

    return preislisteProdukt
  }

  //gewählte Produkte zu PreislisteProdukte umwandeln
  //und der aktuellen Kategorie hinzufügen
  produkteHinzufuegen() {
    this.gewaehlteProdukte.forEach((produkt) => {
      var preislisteProdukt = this.createProdukt(produkt)
      preislisteProdukt.neu = true
      this.aktuelleKategorie.produkte.push(preislisteProdukt)
    })
    this.aktuelleKategorie = null
    this.produkteWaehlenVisible = false
  }

  produktEntfernen(args: any) {
    var kategorie = args.kategorie
    var produkt = args.produkt

    var index = kategorie.produkte.indexOf(produkt)
    kategorie.produkte.splice(index, 1)
  }

  produktDeaktivieren(produkt: PreislisteProdukt) {
    produkt.deaktivieren = true
  }

  produktAktivieren(produkt: PreislisteProdukt) {
    produkt.aktivieren = true
  }

  produkteHinzufuegenAbbrechen() {
    this.gewaehlteProdukte = []
    this.aktuelleKategorie = null
    this.produkteWaehlenVisible = false
  }

  findExtra(extra: Pdukt): PreislisteExtra {
    var index = this.vorhandeneExtras
      .map((x) => {
        return x.produkt.id
      })
      .indexOf(extra.id)
    if (index < 0) {
      var preislisteExtra = new PreislisteExtra()
      preislisteExtra.id = uuidv4()
      preislisteExtra.produkt = extra
      this.vorhandeneExtras.push(preislisteExtra)
      return preislisteExtra
    }
    return this.vorhandeneExtras.at(index)
  }

  navBack() {
    router.back()
  }

  checkCompleted() {
    if (this.extrasLoaded && this.produkteLoaded) {
      //Preisliste laden
      var idRequest = new IdRequest()
      idRequest.id = this.preislisteId

      Poster.postMember<RemoteResult<Pliste>>(
        RestPaths.preislisteDetailsForEdit,
        idRequest
      ).then((response) => {
        this.preisliste = response.data.payload
        if (this.preisliste.gueltigVon != null) {
          this.gueltigVon = moment(
            this.preisliste.gueltigVon,
            'YYYY-MM-DDTHH:mm:ss'
          ).format('YYYY-MM-DD')
        }
        if (this.preisliste.gueltigBis != null) {
          this.gueltigBis = moment(
            this.preisliste.gueltigBis,
            'YYYY-MM-DDTHH:mm:ss'
          ).format('YYYY-MM-DD')
        }
        this.bezeichnung = this.preisliste.bezeichnung

        this.preisliste.kategorien.forEach((kategorie) => {
          var pKategorie = new Kategorie()
          pKategorie.id = kategorie.id
          pKategorie.bezeichnung = kategorie.bezeichnung
          pKategorie.beschreibung = kategorie.beschreibung
          pKategorie.zeitbegrenzung = kategorie.zeitbegrenzung

          this.preislisteKategorien.push(pKategorie)

          kategorie.zeitraeume.forEach((zeitraum) => {
            var pZeitraum = new KategorieZeitraum()
            pZeitraum.wochentag = zeitraum.wochentag
            pZeitraum.zeitVon = zeitraum.zeitVon
            pZeitraum.zeitBis = zeitraum.zeitBis
            pKategorie.zeitraeume.push(pZeitraum)
          })

          kategorie.produkte.forEach((produkt) => {
            var pProdukt = new PreislisteProdukt()
            pProdukt.id = produkt.id
            pProdukt.produkt = this.findProdukt(produkt.pduktId)
            pProdukt.preis = produkt.preis
            pProdukt.aktiv = produkt.aktiv
            pKategorie.produkte.push(pProdukt)

            produkt.extras.forEach((extra) => {
              var pExtra = new PreislisteProduktExtra()
              pExtra.id = extra.id
              pExtra.extraId = extra.extraId
              pExtra.preis = extra.preis
              pExtra.bezeichnung = extra.bezeichnung
              pExtra.artikelNr = extra.artikelNr
              pProdukt.extras.push(pExtra)
            })

            produkt.auswahlen.forEach((auswahl) => {
              var pAuswahl = new PreislisteProduktAuswahl()
              pAuswahl.id = auswahl.id
              pAuswahl.auswahl = new Auswahl()
              var a = this.findAuswahl(auswahl.auswahlId, produkt)
              pAuswahl.auswahl.id = a.auswahlId
              pAuswahl.auswahl.bezeichnung = a.bezeichnung
              pAuswahl.auswahl.beschreibung = a.beschreibung
              pProdukt.auswahlen.push(pAuswahl)

              auswahl.optionen.forEach((option) => {
                var pOption = new PreislisteProduktExtra()
                pOption.id = option.id
                pOption.extraId = option.extraId
                pOption.preis = option.preis
                pOption.bezeichnung = option.bezeichnung
                pOption.artikelNr = option.artikelNr
                pAuswahl.optionen.push(pOption)
              })
            })

            produkt.subprodukte.forEach((subprodukt) => {
              var plSubprodukt = new PreislisteProduktSubprodukt()
              plSubprodukt.id = subprodukt.id
              plSubprodukt.subprodukt = new Subprodukt()
              plSubprodukt.subprodukt.id = subprodukt.subproduktId
              plSubprodukt.subprodukt.bezeichnung = subprodukt.bezeichnung
              pProdukt.subprodukte.push(plSubprodukt)
              subprodukt.optionen.forEach((option) => {
                var pSubproduktOption = new PreislisteProdukt()
                pSubproduktOption.produkt = this.findProdukt(option.pduktId)
                pSubproduktOption.preis = option.preis
                pSubproduktOption.id = option.id
                plSubprodukt.optionen.push(pSubproduktOption)

                option.extras.forEach((extra) => {
                  var pExtra = new PreislisteProduktExtra()
                  pExtra.id = extra.id
                  pExtra.extraId = extra.extraId
                  pExtra.preis = extra.preis
                  pExtra.bezeichnung = extra.bezeichnung
                  pExtra.artikelNr = extra.artikelNr
                  pSubproduktOption.extras.push(pExtra)
                })

                option.auswahlen.forEach((auswahl) => {
                  var pAuswahl = new PreislisteProduktAuswahl()
                  //pAuswahl.id = auswahl.id
                  pAuswahl.auswahl = new Auswahl()
                  pAuswahl.auswahl.id = auswahl.auswahlId
                  var a = this.findAuswahlSubprodukt(auswahl.auswahlId, option)
                  //pAuswahl.auswahl.id = a.id
                  pAuswahl.auswahl.bezeichnung = a.bezeichnung
                  pAuswahl.auswahl.beschreibung = a.beschreibung
                  pSubproduktOption.auswahlen.push(pAuswahl)

                  auswahl.optionen.forEach((option) => {
                    var pOption = new PreislisteProduktExtra()
                    pOption.id = option.id
                    pOption.extraId = option.extraId
                    pOption.preis = option.preis
                    pOption.bezeichnung = option.bezeichnung
                    pOption.artikelNr = option.artikelNr
                    pAuswahl.optionen.push(pOption)
                  })
                })
              })
            })
          })
        })
      })
    }
  }

  init(preislisteId: string, betriebId: string) {
    this.preislisteId = preislisteId

    var request = new PduktRequest()
    request.betriebId = betriebId
    request.extraZusatz = true

    //Extras laden
    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      this.extras = response.data.payload.list
      this.extrasLoaded = true
      this.checkCompleted()
    })

    //Produkte laden
    request.extraZusatz = false

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      this.produkte = response.data.payload.list
      this.produkteLoaded = true
      this.checkCompleted()
    })

    // //Kategorien laden
    // var idRequest = new IdRequest()
    // idRequest.id = betriebId

    // Poster.postMember<RemoteResult<ProduktkategorieList>>(
    //   RestPaths.produktKategorieListe,
    //   idRequest
    // ).then((response) => {
    //   this.kategorien = response.data.payload.list
    //   this.kategorienLoaded = true
    //   this.checkCompleted()
    // })
  }

  // findKategorie(kategorieId: string): Produktkategorie {
  //   var index = this.kategorien.findIndex((element) => {
  //     return element.id === kategorieId
  //   })
  //   return this.kategorien[index]
  // }

  kategorieBearbeiten(kategorie: Kategorie) {
    this.kategorieEditBackup = kategorie
    this.kategorieEdit = new KategorieComponent()
    this.kategorieEdit.bezeichnung = kategorie.bezeichnung
    this.kategorieEdit.beschreibung = kategorie.beschreibung
    this.kategorieEdit.zeitbegrenzung = kategorie.zeitbegrenzung

    kategorie.zeitraeume.forEach((zeitraum) => {
      var z = new KategorieZeitraumComponent()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      this.kategorieEdit.zeitraeume.push(z)
    })

    this.kategorieEditDialogVisible = true
  }

  kategorieBearbeitenAbbrechen() {
    this.kategorieEditDialogVisible = false
    this.kategorieEdit = null
    this.kategorieEditBackup = null
  }

  kategorieBearbeitenSpeichern() {
    this.kategorieEditBackup.bezeichnung = this.kategorieEdit.bezeichnung
    this.kategorieEditBackup.beschreibung = this.kategorieEdit.beschreibung
    this.kategorieEditBackup.zeitbegrenzung = this.kategorieEdit.zeitbegrenzung
    this.kategorieEditBackup.zeitraeume = []
    this.kategorieEdit.zeitraeume.forEach((zeitraum) => {
      var z = new KategorieZeitraum()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      this.kategorieEditBackup.zeitraeume.push(z)
    })

    this.kategorieEditDialogVisible = false
    this.kategorieEdit = null
    this.kategorieEditBackup = null
  }

  findProdukt(produktId: string): Pdukt {
    var index = this.produkte.findIndex((element) => {
      return element.id === produktId
    })
    return this.produkte[index]
  }

  findFromExtra(extraId: string): Pdukt {
    var index = this.extras.findIndex((element) => {
      return element.id === extraId
    })
    return this.extras[index]
  }

  findAuswahl(auswahlId: string, produkt: PlistePdukt): PduktAuswahl {
    var index = produkt.auswahlen.findIndex((element) => {
      return element.auswahlId === auswahlId
    })
    return produkt.auswahlen[index]
  }

  findAuswahlSubprodukt(
    auswahlId: string,
    option: SubproduktOption
  ): PduktAuswahl {
    var index = option.auswahlen.findIndex((element) => {
      return element.auswahlId === auswahlId
    })
    return option.auswahlen[index]
  }

  preislisteSpeichern() {
    this.editWorking = true
    this.editFormDisabled = true
    this.editError = false

    var request = new PlisteUpdateRequest()
    request.id = this.preisliste.id
    if (this.gueltigVon != null) {
      request.gueltigVon = moment(this.gueltigVon, 'YYYY-MM-DD').format(
        'YYYY-MM-DDTHH:mm:ss'
      )
    }
    if (this.gueltigBis != null) {
      request.gueltigBis = moment(this.gueltigBis, 'YYYY-MM-DD').format(
        'YYYY-MM-DDTHH:mm:ss'
      )
    }

    request.bezeichnung = this.bezeichnung

    this.preislisteKategorien.forEach((prlKategorie) => {
      var reqKategorie = new ReqKategorie()
      reqKategorie.id = prlKategorie.id
      reqKategorie.bezeichnung = prlKategorie.bezeichnung
      reqKategorie.beschreibung = prlKategorie.beschreibung
      reqKategorie.zeitbegrenung = prlKategorie.zeitbegrenzung

      prlKategorie.zeitraeume.forEach((zeitraum) => {
        var reqZeitraum = new ReqKategorieZeitraum()
        reqZeitraum.wochentag = zeitraum.wochentag
        reqZeitraum.zeitVon = zeitraum.zeitVon
        reqZeitraum.zeitBis = zeitraum.zeitBis
        reqKategorie.zeitbegrenzungZeitraeume.push(reqZeitraum)
      })

      //reqKategorie.kategorieId = prlKategorie.kategorie.id
      request.kategorien.push(reqKategorie)

      prlKategorie.produkte.forEach((prlProdukt) => {
        var reqProdukt = new ReqProdukt()
        reqProdukt.id = prlProdukt.id
        reqProdukt.produktId = prlProdukt.produkt.id
        reqProdukt.preis = prlProdukt.preis
        reqProdukt.neu = prlProdukt.neu
        reqProdukt.deaktivieren = prlProdukt.deaktivieren
        reqProdukt.aktivieren = prlProdukt.aktivieren
        reqKategorie.produkte.push(reqProdukt)

        prlProdukt.extras.forEach((prlExtra) => {
          var reqExtra = new ReqExtra()
          reqExtra.id = prlExtra.id
          reqExtra.extraId = prlExtra.extraId
          reqExtra.preis = prlExtra.preis
          reqProdukt.extras.push(reqExtra)
        })

        prlProdukt.auswahlen.forEach((prlAuswahl) => {
          var reqAuswahl = new ReqAuswahl()
          reqAuswahl.id = prlAuswahl.id
          reqAuswahl.auswahlId = prlAuswahl.auswahl.id
          reqProdukt.auswahlen.push(reqAuswahl)

          prlAuswahl.optionen.forEach((prlOption) => {
            var reqOption = new ReqOption()
            reqOption.id = prlOption.id
            reqOption.optionId = prlOption.extraId
            reqOption.preis = prlOption.preis

            reqAuswahl.optionen.push(reqOption)
          })
        })

        prlProdukt.subprodukte.forEach((prlSubprodukt) => {
          var reqSubprodukt = new ReqSubprodukt()
          reqSubprodukt.id = prlSubprodukt.id
          reqSubprodukt.subproduktId = prlSubprodukt.subprodukt.id
          reqProdukt.subprodukte.push(reqSubprodukt)

          prlSubprodukt.optionen.forEach((prlOption) => {
            var reqSubproduktOption = new ReqProdukt()
            if (prlOption.id == null) {
              prlOption.id = uuidv4()
            } else {
              reqSubproduktOption.id = prlOption.id
            }
            reqSubproduktOption.produktId = prlOption.produkt.id
            reqSubproduktOption.preis = prlOption.preis
            console.log('Preis der Option: ' + prlOption.preis)

            reqSubprodukt.optionen.push(reqSubproduktOption)

            prlOption.extras.forEach((prlExtra) => {
              var reqExtra = new ReqExtra()
              reqExtra.id = prlExtra.id
              reqExtra.extraId = prlExtra.extraId
              console.log('Die Extra ID: ' + prlExtra.extraId)
              reqExtra.preis = prlExtra.preis
              reqSubproduktOption.extras.push(reqExtra)
            })

            prlOption.auswahlen.forEach((prlAuswahl) => {
              var reqAuswahl = new ReqAuswahl()
              reqAuswahl.id = prlAuswahl.id
              reqAuswahl.auswahlId = prlAuswahl.auswahl.id
              reqSubproduktOption.auswahlen.push(reqAuswahl)

              prlAuswahl.optionen.forEach((prlOption) => {
                var reqOption = new ReqOption()
                reqOption.id = prlOption.id
                reqOption.optionId = prlOption.extraId
                reqOption.preis = prlOption.preis
                reqAuswahl.optionen.push(reqOption)
              })
            })
          })
        })
      })
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.preislisteUpdate, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
        this.editFormDisabled = false
      })
  }
}

class Kategorie {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitraeume: KategorieZeitraum[] = []
  produkte: PreislisteProdukt[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitBis?: string
}

class PreislisteExtra {
  id: string
  produkt: Pdukt
  preis?: number = null
}

class PreislisteProdukt {
  id: string
  neu: boolean = false
  deaktivieren: boolean = false
  aktivieren: boolean = false
  aktiv: boolean = true
  produkt: Pdukt
  extras: PreislisteProduktExtra[] = []
  auswahlen: PreislisteProduktAuswahl[] = []
  preis: number = null
  subprodukte: PreislisteProduktSubprodukt[] = []
  index: number = 0
}

class PreislisteProduktExtra {
  id: string
  extraId: string
  preis: number
  bezeichnung: string
  artikelNr: string
}

class PreislisteProduktAuswahl {
  id: string
  auswahl: Auswahl
  optionen: PreislisteProduktExtra[] = []
}

class PreislisteProduktSubprodukt {
  id: string
  subprodukt: Subprodukt
  optionen: PreislisteProdukt[] = []
}

export {
  Kategorie,
  PreislisteExtra,
  PreislisteProdukt,
  PreislisteProduktExtra,
  PreislisteProduktAuswahl,
}
