import RemoteResult from '@/entities/common/remote_result'
import BenutzerRegistrierenRequest from '@/entities/common/request/benutzer_registrieren_request'
import vuexStore from '@/store'
import InputValidator from '@/util/input_validator'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Validator from '@/util/validator'

export default class RegistrierenLogic {
  nachname?: string = null
  vorname?: string = null
  benutzername?: string = null
  passwort?: string = null
  passwortWiederholen?: string = null
  showPasswort: boolean = false
  agbsAkzeptiert: boolean = false

  registrierenWorking: boolean = false
  registrierenError: boolean = false
  registrierenCompleted: boolean = false
  formDisabled: boolean = false

  emailRules = Validator.emailValidate
  passwortRules = Validator.strongPasswordRules
  nachnameRules = [(v) => !!v || 'Bitte Nachname angeben']
  vornameRules = [(v) => !!v || 'Bitte Vorname angeben']

  init() {}

  registrieren() {
    this.registrierenWorking = true
    this.registrierenError = false
    this.registrierenCompleted = false
    this.formDisabled = true

    var request = new BenutzerRegistrierenRequest()
    request.nachname = this.nachname
    request.vorname = this.vorname
    request.benutzername = this.benutzername
    request.passwort = this.passwort
    request.identifier = vuexStore.state.user.identifier

    Poster.postUser<RemoteResult<any>>(
      RestPaths.kundeBenutzerRegistrieren,
      request
    )
      .then((response) => {
        this.registrierenWorking = false
        if (response.data.success) {
          this.registrierenCompleted = true
        } else {
          this.registrierenError = true
          this.formDisabled = false
        }
      })
      .catch(() => {
        this.registrierenWorking = false
        this.registrierenError = true
        this.formDisabled = false
      })
  }
}
