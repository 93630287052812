
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '@/components/mixins/Calculator.vue'
import { PreislisteProdukt } from '@/entities/common/pliste_produkt_zeit'

@Component
export default class Artikel extends mixins(Calculator) {
  @Prop() produkt: PreislisteProdukt

  clickProdukt(produkt: PreislisteProdukt) {
    if (!produkt.ausverkauft) this.produktSelected(produkt)
  }

  @Emit('selected')
  produktSelected(produkt: PreislisteProdukt) {}

  berechneStandardpreis(produkt: PreislisteProdukt): number {
    if (produkt.hasVarianten) {
      var sId = produkt.standardvarianteId
      var preis = 0
      produkt.varianten.forEach((v) => {
        if (v.varianteId === sId) {
          preis = v.preis
          return
        }
      })
      return preis
    } else {
      return produkt.preis
    }
  }
}
