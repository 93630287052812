
import Component, { mixins } from 'vue-class-component'
import Calculator from './mixins/Calculator.vue'

@Component
export default class VarianteTest extends mixins(Calculator) {
  anzahl: number = 1
  produkt: Produkt = null
  selectedExtras: Extra[] = []

  berechneGesamtpreis(): number {
    if (!this.produkt.variante) return 0
    var preis = this.produkt.variante.preis

    var varianteId = this.produkt.variante.varianteId
    this.selectedExtras.forEach((e) => {
      e.varianten.forEach((v) => {
        if (v.varianteId === varianteId) {
          preis += v.preis
          return
        }
      })
    })

    return preis * this.anzahl
  }

  berechneExtraPreis(extra: Extra): number {
    if (!this.produkt.variante) return
    var result: number = 0
    extra.varianten.forEach((v) => {
      if (v.varianteId == this.produkt.variante.varianteId) {
        result = v.preis
        return
      }
    })
    return result
  }

  incrementAnzahl() {
    this.anzahl++
  }

  decrementAnzahl() {
    this.anzahl--
  }

  mounted() {
    this.produkt = new Produkt()
    this.produkt.bezeichnung = 'Das tolle Produkt'

    var variante1 = new ProduktVariante()
    variante1.varianteId = 1
    variante1.bezeichnung = '20cm'
    variante1.preis = 20
    this.produkt.varianten.push(variante1)

    var variante2 = new ProduktVariante()
    variante2.varianteId = 2
    variante2.bezeichnung = '40cm'
    variante2.preis = 30
    this.produkt.varianten.push(variante2)

    var extra1 = new Extra()
    extra1.bezeichnung = 'Wurst'
    this.produkt.extras.push(extra1)

    var extraVariante11 = new ExtraVariante()
    extraVariante11.varianteId = 1
    extraVariante11.preis = 2
    extra1.varianten.push(extraVariante11)

    var extraVariante12 = new ExtraVariante()
    extraVariante12.varianteId = 2
    extraVariante12.preis = 3
    extra1.varianten.push(extraVariante12)

    var extra2 = new Extra()
    extra2.bezeichnung = 'Milch'
    this.produkt.extras.push(extra2)

    var extraVariante21 = new ExtraVariante()
    extraVariante21.varianteId = 1
    extraVariante21.preis = 5
    extra2.varianten.push(extraVariante21)

    var extraVariante22 = new ExtraVariante()
    extraVariante22.varianteId = 2
    extraVariante22.preis = 6
    extra2.varianten.push(extraVariante22)

    this.produkt.variante = variante1
  }
}

class Produkt {
  bezeichnung: string
  varianten: ProduktVariante[] = []

  variante: ProduktVariante = null
  extras: Extra[] = []
}

class ProduktVariante {
  varianteId: number
  bezeichnung: string
  preis: number
}

class Extra {
  bezeichnung: string
  varianten: ExtraVariante[] = []
}

class ExtraVariante {
  varianteId: number
  preis: number
}
