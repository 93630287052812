
import Pdukt from '@/entities/member/pdukt'
import { Auswahl as PduktAuswahl } from '@/entities/member/pliste_pdukt'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Expander from '../custom/Expander.vue'
import PlistePdukt, { SubproduktOption } from '@/entities/member/pliste_pdukt'
import PreislisteKategorieEditContainer from './PreislisteKategorieEditContainer.vue'
import PreislisteProduktEditContainer from './PreislisteProduktEditContainer.vue'
import { Prop } from 'vue-property-decorator'
import NewPreislisteEditMemcommonLogic, {
  PreislisteExtra,
  PreislisteProdukt,
  Kategorie,
} from '@/logic/memcommon/new_preisliste_edit_memcommon_logic'
import KategorieEdit from '../custom/KategorieEdit.vue'
@Component({
  components: {
    ToolbarButton,
    Expander,
    PreislisteProduktEditContainer,
    PreislisteKategorieEditContainer,
    KategorieEdit,
  },
})
export default class NewPreislisteEditMemCommon extends mixins(Calculator) {
  $refs: {
    datumVonDialog: HTMLFormElement
    datumBisDialog: HTMLFormElement
  }

  @Prop() logic: NewPreislisteEditMemcommonLogic

  //Aus Kategorie eine Preislistenkategorie machen
  kategorieHinzufuegen(kategorie: Kategorie) {
    this.logic.kategorieHinzufuegen(kategorie)
  }

  neueKategorieErstellen() {
    this.logic.neueKategorieErstellen()
  }

  neueKategorieEinfuegen() {
    this.logic.neueKategorieEinfuegen()
  }

  neueKategorieAbbrechen() {
    this.logic.neueKategorieAbbrechen()
  }

  kategorieBearbeiten(kategorie: Kategorie) {
    this.logic.kategorieBearbeiten(kategorie)
  }

  kategorieBearbeitenAbbrechen() {
    this.logic.kategorieBearbeitenAbbrechen()
  }

  kategorieBearbeitenSpeichern() {
    this.logic.kategorieBearbeitenSpeichern()
  }

  moveKategorieUp(index: number) {
    this.logic.moveKategorieUp(index)
  }

  moveKategorieDown(index: number) {
    this.logic.moveKategorieDown(index)
  }

  //Preiliste Kategorie löschen
  kategorieEntfernen(kategorie: Kategorie) {
    return this.logic.kategorieEntfernen(kategorie)
  }

  kategorieProdukteWaehlen(kategorie: Kategorie) {
    this.logic.kategorieProdukteWaehlen(kategorie)
  }

  createProdukt(produkt: Pdukt): PreislisteProdukt {
    return this.logic.createProdukt(produkt)
  }

  //gewählte Produkte zu PreislisteProdukte umwandeln
  //und der aktuellen Kategorie hinzufügen
  produkteHinzufuegen() {
    this.logic.produkteHinzufuegen()
  }

  produktEntfernen(args: any) {
    var kategorie = args.kategorie
    var produkt = args.produkt

    var index = kategorie.produkte.indexOf(produkt)
    kategorie.produkte.splice(index, 1)
  }

  produktDeaktivieren(produkt: PreislisteProdukt) {
    produkt.deaktivieren = true
  }

  produktAktivieren(produkt: PreislisteProdukt) {
    produkt.aktivieren = true
  }

  produkteHinzufuegenAbbrechen() {
    this.logic.produkteHinzufuegenAbbrechen()
  }

  findExtra(extra: Pdukt): PreislisteExtra {
    return this.logic.findExtra(extra)
  }

  navBack() {
    this.logic.navBack()
  }

  checkCompleted() {
    this.logic.checkCompleted()
  }

  findProdukt(produktId: string): Pdukt {
    return this.logic.findProdukt(produktId)
  }

  findFromExtra(extraId: string): Pdukt {
    return this.logic.findFromExtra(extraId)
  }

  findAuswahl(auswahlId: string, produkt: PlistePdukt): PduktAuswahl {
    return this.logic.findAuswahl(auswahlId, produkt)
  }

  findAuswahlSubprodukt(
    auswahlId: string,
    option: SubproduktOption
  ): PduktAuswahl {
    return this.logic.findAuswahlSubprodukt(auswahlId, option)
  }

  preislisteSpeichern() {
    this.logic.preislisteSpeichern()
  }
}
