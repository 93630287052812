var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"mb-3",attrs:{"color":"transparent"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.artikelSync.anzahl)+" x "+_vm._s(_vm.artikelSync.produkt.bezeichnung)+" ")]),_c('span',{staticClass:"font-weight-black"},[_vm._v(" (a "+_vm._s(_vm.formatiereWaehrung(_vm.artikelSync.produkt.preis))+") ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-right font-weight-black blue--text"},[_vm._v(" "+_vm._s(_vm.formatiereWaehrung(_vm.berechnePreis(_vm.artikelSync)))+" ")])])],1),(_vm.artikelSync.produkt.variante)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"d-block ml-4"},[_vm._v("Variante: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.artikelSync.produkt.variante.bezeichnung))])])])],1):_vm._e()],1),(
        _vm.artikelSync.produkt.extras && _vm.artikelSync.produkt.extras.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.berechneExtras()))])]):_vm._e(),(
        _vm.artikelSync.produkt.auswahlen &&
        _vm.artikelSync.produkt.auswahlen.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Auswahlen")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((_vm.artikelSync.produkt.auswahlen),function(auswahl){return _c('div',{key:'auswahl-' + auswahl.id,staticClass:"mb-2"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(auswahl.bezeichnung))]),_c('span',{staticClass:"d-block font-weight-bold"},[_c('span',[_vm._v(_vm._s(auswahl.option.bezeichnung))]),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(auswahl.option.preis))+")")])])])})],2)],1):_vm._e(),(
        _vm.artikelSync.produkt.subprodukte &&
        _vm.artikelSync.produkt.subprodukte.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic"},[_vm._v("Gewählte Produkte")]),_vm._l((_vm.artikelSync.produkt.subprodukte),function(subprodukt){return _c('v-sheet',{key:'subprodukt-' + subprodukt.id,staticClass:"pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(subprodukt.bezeichnung))]),_c('span',{staticClass:"font-weight-bold d-block mb-2"},[_vm._v(_vm._s(subprodukt.produkt.bezeichnung)+" "),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(subprodukt.produkt.preis))+")")])]),(
              subprodukt.produkt.extras &&
              subprodukt.produkt.extras.length > 0
            )?_c('v-sheet',{staticClass:"ma-2 pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Extras")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((subprodukt.produkt.extras),function(extra){return _c('div',{key:'subextra-' + extra.id,staticClass:"d-block"},[_c('span',[_vm._v(_vm._s(extra.bezeichnung))]),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(extra.preis))+")")])])})],2):_vm._e(),(
              subprodukt.produkt.auswahlen &&
              subprodukt.produkt.auswahlen.length > 0
            )?_c('v-sheet',{staticClass:"ma-2 pa-2",attrs:{"outlined":""}},[_c('span',{staticClass:"d-block font-italic mb-1"},[_vm._v("Auswahlen")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((subprodukt.produkt.auswahlen),function(auswahl){return _c('div',{key:'subauswahl-' + auswahl.id,staticClass:"mb-2"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(auswahl.bezeichnung))]),_c('span',{staticClass:"d-block font-weight-bold"},[_c('span',[_vm._v(_vm._s(auswahl.option.bezeichnung))]),_c('span',{staticClass:"blue--text"},[_vm._v(" (+"+_vm._s(_vm.formatiereWaehrung(auswahl.option.preis))+")")])])])})],2):_vm._e()],1)})],2)],1):_vm._e()],1),_c('v-divider',{staticClass:"mt-2 mb-2"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }