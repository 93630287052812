import Inhaltsstoff from '../common/inhaltsstoff'

export default class Pdukt {
  id?: string
  aktiv: boolean = true
  bezeichnung?: string
  beschreibung?: string
  artikelNr?: string
  vegetarisch?: boolean
  vegan?: boolean
  hot1?: boolean
  hot2?: boolean
  hot3?: boolean
  standardpreis: number = 0.0
  extraZusatz: boolean
  menu: boolean
  kategorieId?: string
  kategorieBezeichnung?: string
  bildId?: string
  inhaltsstoffe: Inhaltsstoff[] = []
  subprodukte: Subprodukt[] = []
  extras: Extra[] = []
  auswahlen: Auswahl[] = []
  varianten: Variante[] = []
}

class Subprodukt {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  optionen: Option[] = []
}

class Extra {
  id?: string
  produkt?: Pdukt
}

class Auswahl {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  optionen: Option[] = []
}

class Option {
  id?: string
  produkt?: Pdukt
  variante?: Variante
}

class Variante {
  id?: string
  bezeichnung?: string
}

export { Subprodukt, Extra, Auswahl, Option, Variante }
