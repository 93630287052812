class Kategorie {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitraueme: KategorieZeitraum[] = []
  produkte: PreislisteProdukt[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitBis?: string
}

class Produkt {
  id?: string
  bezeichnung?: string
  variante?: string
  varianteId?: string
  artikelNr?: string
  standardpreis: number = 0.0

  extras: ProduktExtra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
  varianten: Variante[] = []
}

class Variante {
  id: string
  bezeichnung: string
}

class Subprodukt {
  id?: string
  bezeichnung?: string

  optionen: Produkt[] = []
}

class ProduktExtra {
  pduktExtraId?: string
  produkt?: Produkt
}

class Auswahl {
  id?: string
  bezeichnung?: string
  optionen: ProduktExtra[] = []
}

class PreislisteProdukt {
  produkt: Produkt
  extras: PreislisteProduktExtra[] = []
  auswahlen: PreislisteProduktAuswahl[] = []
  preis: number
  hasVarianten: boolean = false
  varianten: PreisVariante[] = []
  shortcut: string
  index: number = 0
  subprodukte: PreislisteProduktSubprodukt[] = []
}

class PreisVariante {
  id: string
  bezeichnung: string
  preis: number
}

class PreislisteProduktExtra {
  extra: ProduktExtra
  pduktExtraId: string
  preis: number
  index: number = 0
  varianten: PreisVariante[] = []
}

class PreislisteProduktOption {}

class PreislisteProduktAuswahl {
  id: string
  auswahl: Auswahl
  optionen: PreislisteProduktExtra[] = []
}

class PreislisteProduktSubprodukt {
  id: string
  subprodukt: Subprodukt
  optionen: PreislisteProdukt[] = []
}

export {
  Kategorie,
  KategorieZeitraum,
  Produkt,
  Subprodukt,
  ProduktExtra,
  Auswahl,
  Variante,
  PreisVariante,
  PreislisteProdukt,
  PreislisteProduktExtra,
  PreislisteProduktOption,
  PreislisteProduktAuswahl,
  PreislisteProduktSubprodukt,
}
