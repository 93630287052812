
import Bestellung from '@/entities/common/bestellung'
import BestellungenLogic from '@/logic/bestellungen_logic'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from './custom/FilialeHeader.vue'
import BestellungRow from './custom/BestellungRow.vue'
import Calculator from './mixins/Calculator.vue'
import Formatter from '@/logic/formatter'
import ErrorBox from './custom/ErrorBox.vue'

@Component({ components: { FilialeHeader, BestellungRow, ErrorBox } })
export default class Bestellungen extends mixins(Calculator) {
  logic?: BestellungenLogic = null

  mounted() {
    this.logic = new BestellungenLogic()
    this.logic.init()
  }

  formatiereAbholungStatus(bestellung: Bestellung): string {
    return this.logic.formatiereAbholungStatus(bestellung)
  }

  formatiereLieferungStatus(bestellung: Bestellung): string {
    return this.logic.formatiereLieferungStatus(bestellung)
  }

  computeReservierungZeit(value: Bestellung): string {
    return (
      Formatter.formatiereDatum(value.reservierung.zeitVon) +
      ' ' +
      Formatter.formatiereUhrzeit(value.reservierung.zeitVon) +
      '-' +
      Formatter.formatiereUhrzeit(value.reservierung.zeitBis)
    )
  }

  detailsAnzeigen(bestellung: Bestellung) {
    this.logic.detailsAnzeigen(bestellung)
  }

  abgeschlosseneAnzeigen() {
    this.logic.abgeschlosseneAnzeigen()
  }
}
